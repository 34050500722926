.App{
    min-height:100vh;
    position: relative;
    color: white;
}

nav{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255,255,255,1);
    color: #000736;
    position: fixed;
    width:100%;
    z-index: 99;
    box-shadow: 0px 2px 8px#000736;
}

nav h2{
    font-style: italic;
    margin: 1rem 1rem 1rem 2rem;
    font-size: 2rem;
}

.nav--links{
    margin-right: 1rem;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
}

.nav--links a{
    color:#19378d;
    font-size:1.25rem;
    text-decoration: none;
    margin:0 1rem;
    border-radius: 3px;
    padding:5px;
}

.nav--links a:hover,
.nav--links a:focus,
.active{
    color:#fff;
    background-color:#000736;
    transition: all 0.3s ease-in-out;
}

.menu--icon{
    display: none;
}

.main--container{
    height:100%;
    width: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;

}

.hero--container{
    width: 100%;
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    background:url("../assets/nasa_light.jpg");
    background-size:cover;
    background-position: 20%;
}

#services{
    scroll-margin-top: 100px;
}
#about{
    scroll-margin-top: 100px;
}
#contact{
    scroll-margin-top: 100px;
}
.services--container{
    width: 100%;
    max-width: 1200px;
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    align-items:center;
    flex-wrap: wrap;
    color:#000736;
}

.about--container{
    width: 100%;
    padding:1rem;
    background:#000736;
}

.about--inner{
    width: 100%;
    max-width: 1200px;
    margin:0 auto;
    padding:1rem;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    color:white;
    background:#000736;
}

.about--container img{
    margin-right: 1rem;
    border-radius: 10px;
    max-width: 50%;
}

.about--text{
    line-height: 1.5rem;
    font-size: 1.25rem;
}

.contact--container{
    width: 100%;
    min-height: 25vh;
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    align-items:center;
    color:#000736;
}

.contact--info{
    display:flex;
    align-items: center;
}

.card{
    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width:300px;
    line-height: 1.5rem;
    margin: 2rem 1rem;
}

.card img{
    max-width:250px;
    height: 150px;
    border-radius: 10px;
}

.card h3{
    margin-bottom:0.25rem;
}

.card p{
    margin-top: 0.25rem;
    font-weight: 400;
}

.hero{
    color:white;
    text-align: center;
    font-style: italic;
    border: 1px solid grey;
    padding: 2rem;
    background: rgba(0,0,0,0.8);
    width:100%;
    max-width: 800px;
    border-radius: 15px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn--container{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:center;
    width: 200px;
}

.btn{
    padding: 10px;
    color: white;
    text-decoration: none;
    font-style:normal;
    font-weight: 600;
    background-color: #19378d;
    border-radius: 5px;
}

.btn:hover,
.btn:focus{
    transform: scale(1.1);
}

.hero h1{
    font-size:3rem;
    margin-bottom: 0.5rem;
}


@media (max-width:900px){
    nav{
        flex-direction:column;
    }
    nav h2{
        margin-bottom: 1rem;
    }
    .logo--bars {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items: center;
    }
    .menu--icon{
        display:block;
        cursor:pointer;
        transform: rotate(90deg);
        transition: all 0.5s ease-in-out;
    }
    .menu--icon .times{
        transform: rotate(180deg);
        transition: all 0.2s ease-in-out;
    }
    .menu--icon .bars{
        transform: rotate(90deg);
        transition: all 0.2s ease-in-out;
    }
    .nav--links{
        align-items:center;
        margin-right: 1rem;
        flex-direction: column;
        justify-content: space-around;
        width:100%;
        transition: all 0.5s ease-in-out;
        height: auto;
        overflow:hidden;
        transform-origin:top;
        padding-top: 1rem;
        border-top: 1px solid #000736;
    }
    .nav--links.close{
        display:none;
    }
    .nav--links a{
        width:100px;
        text-align: center;
    }
    .about--inner{
        flex-direction: column;
        align-items: center;
    }
    .contact--container{
        flex-direction:column;
        justify-content: center;
    }
    .contact--info{
        justify-content: flex-start;
        align-items:center;
    } 
}


